import { SetStateAction, useContext, useEffect, useState } from "react"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"
import { GlobalContext } from "context/context"
// import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { Helmet } from "react-helmet"
import alert_icon from "assets/icons/red-circle-svgrepo-com.svg"
import logo from "theme/assets/img/ico_header_logo.svg"

interface ISummaryPopupChanges {
  data_status: {
    state: any
    dispatch: SetStateAction<any>
  }
  user: any
  call_service_counter: any
  shippingType: string
}

const SummaryPopupChanges = ({ user, data_status, call_service_counter, shippingType }: ISummaryPopupChanges) => {
  const { notification } = useContext(GlobalContext)
  const [reload, set_reload] = useState<boolean>(false)
  const { post_middleware_query_action } = usePostMiddlewareOsrm()
  const is_popup_opened = notification.state.some((not: any) => not?.id === 1001)

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!reload) {
      timeout = setTimeout(() => set_reload(true), 60000)
      // timeout = setTimeout(() => set_reload(true), 8000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [reload])

  useEffect(() => {
    if (!is_popup_opened) {
      reload && call_verify_data()
    }
    // eslint-disable-next-line
  }, [reload, notification])

  const call_verify_data = async () => {
    let body: any = {
      filters: {
        "_omnixStateMachine.currentState.state.name": "ASSIGNED",
        shippingType
        // "_omnixStateMachine.currentState.state.name": "PICKING_COMPLETED_PENDING_PACKAGE",
      },
      groupBy: {
        "_omnixStateMachine.currentState.state.name": {
          size: 50,
        },
      },
    }

    if (Boolean(user?.currentSources?.length)) {
      Object.assign(body.filters, user?.currentSources?.includes("ALL") ? {} : { "source.id": user?.currentSources })
    }

    const response = await post_middleware_query_action("search/shipping_groups/group", body)
    let statusses = response?.data?.message["_omnixStateMachine.currentState.state.name"] || []

    statusses = statusses.map((status: any) => {
      const [key] = Object.keys(status)
      const [value] = Object.values(status)
      const _val = {
        status: key,
        title: key,
        optionCount: value,
      }
      return _val
    })

    let status_with_changes: any = []

    data_status?.state?.map((sts: any) => {
      const status_to_compare = statusses.find(
        (newsts: any) => newsts?.status?.toUpperCase() === sts?.id?.toUpperCase(),
      )
      if (status_to_compare) {
        if (sts?.optionCount !== status_to_compare?.optionCount) {
          status_with_changes.push(sts?.id?.toUpperCase())
        }
      }
      return true
    })
    if (status_with_changes?.length) {
      // set_changed_status(status_with_changes)
      // refModal.current?.open()
      call_service_counter()
      notification?.dispatch({ type: "DELETE_NOTIFICATION", payload: 1001 })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Se han detectado nuevas órdenes para procesar.",
          title: "Hay nuevas órdenes",
          type: "success",
          id: 1001,
          useDelay: false,
          onDismiss,
        },
      })
    }
    // --
    set_reload(false)
  }

  const onDismiss = () => set_reload(false)

  return is_popup_opened ? (
    <Helmet>
      <link rel="icon" href={alert_icon} />
      <title>Entel | Hay nuevas órdenes</title>
    </Helmet>
  ) : (
    <Helmet>
      <link rel="icon" href={logo} />
    </Helmet>
  )
}

export default SummaryPopupChanges
