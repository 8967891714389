import { Env } from "classes"

export const reason_cancel_body = (reason: string, sg: any, userName: string) => {
  const reasons: { [key: string]: any } = {
    CLIENT_REQUEST: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "CLIENT_REQUEST",
        description: "Cancelar SG por solicitud del cliente",
        executer: {
          id: userName, //id de front
          name: "Omnix Store", //OMNIX
        },
        channelId: "", //enviar vacio
        customerOrderId: sg?.salesChannelId, //ID de EOC
        requestId: "", //enviar vacio
        returnMethod: "", //enviar vacio
        returnType: "", //enviar vacio
        status: "canceled", //enviar canceled
        employeeId: "", //enviar vacio
        storeId: "", //enviar vacio
      },
    },
    PICKING_ERROR: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "PICKING_ERROR",
        description: "Cancelar SG por error de picking en ORSIM",
        executer: {
          id: userName, //id de front
          name: "Omnix Store", //OMNIX
        },
      },
    },
    SERIES_NOT_AVAILABLE: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "SERIES_NOT_AVAILABLE",
        description: "Cancelar SG por series no disponibles",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    DATA_ERROR: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "DATA_ERROR",
        description: "Cancelar SG por error de datos en ORSIM",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    SINISTER_ORDER: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "SINISTER_ORDER",
        description: "Cancelar SG por siniestro",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    MAX_DELIVERY_ATTEMPT: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "MAX_DELIVERY_ATTEMPT",
        description: "Cancelar SG por maximo intento de entrega",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    NOT_DELIVERY_PAYMENT: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "NOT_DELIVERY_PAYMENT",
        description: "Cancelar SG por no pago contra entrega",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    BIOMETRIC_FAILED: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "BIOMETRIC_FAILED",
        description: "Cancelar SG por validación biometrica fallida",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
    UNKNOW_REASON: {
      entityId: sg?.salesChannelId,
      action: "CANCEL",
      custom: {
        reason: "UNKNOW_REASON",
        description: "Motivo desconocido",
        executer: {
          id: userName, //id de front
          name: "Omnix Store",
        },
      },
    },
  }
  return reasons?.hasOwnProperty(reason) ? reasons[reason] : {}
}

const CLIENT_REQUEST = "Cancelación por solicitud del cliente"
const PICKING_ERROR = "Error al generar picking ID"
const SERIES_NOT_AVAILABLE = "Series no disponible"
const DATA_ERROR = "Error de datos en ORSIM"
const SINISTER_ORDER = "Cancelar orden por Siniestro"
const MAX_DELIVERY_ATTEMPT = "Cancelar por máximos intentos de entrega"
const NOT_DELIVERY_PAYMENT = "Cancelar por no pago contra entrega"
const BIOMETRIC_FAILED = "Cancelación por validación biométrica fallida"
const UNKNOW_REASON = "Motivo desconocido"

export const reason_cancel_delivered_source_body = (reason: string, sg: any, userName: string) => {
  //eslint-disable-next-line
  const description_reason = eval(reason)

  return {
    entityId: sg?.salesChannelId,
    action: "DELIVERED_IN_SOURCE",
    custom: {
      description: "Pedido retornado en tienda",
      sourceId: sg?.source?.id,
      location: {
        latitude: "",
        longitude: "",
      },
      executer: {
        id: userName, //id de front
        name: "Omnix Store",
      },
    },
    customCancel: {
      reason,
      description: description_reason,
    },
  }
}

const get_cancel_reasons = (current_status: string, shippingtype: string) => {
  const statusses = Env.theme.entel.sg[shippingtype.toUpperCase()].status

  const cancel_reasons_hd: any = {
    [statusses[0]]: [{ label: CLIENT_REQUEST, value: "CLIENT_REQUEST" }],
    [statusses[1]]: [{ label: CLIENT_REQUEST, value: "CLIENT_REQUEST" }],
    [statusses[2]]: [
      { label: CLIENT_REQUEST, value: "CLIENT_REQUEST" },
      { label: PICKING_ERROR, value: "PICKING_ERROR" },
    ],
    [statusses[3]]: [
      { label: CLIENT_REQUEST, value: "CLIENT_REQUEST" },
      { label: SERIES_NOT_AVAILABLE, value: "SERIES_NOT_AVAILABLE" },
      { label: PICKING_ERROR, value: "PICKING_ERROR" },
      { label: DATA_ERROR, value: "DATA_ERROR" },
    ],
    // -- Same State With Typo From Backend
    [statusses[8]]: [{ label: SINISTER_ORDER, value: "SINISTER_ORDER" }],
    [statusses[20]]: [{ label: SINISTER_ORDER, value: "SINISTER_ORDER" }],
    // --
    [statusses[9]]: [
      { label: SINISTER_ORDER, value: "SINISTER_ORDER" },
      { label: MAX_DELIVERY_ATTEMPT, value: "MAX_DELIVERY_ATTEMPT" },
      { label: NOT_DELIVERY_PAYMENT, value: "NOT_DELIVERY_PAYMENT" },
      { label: BIOMETRIC_FAILED, value: "BIOMETRIC_FAILED" },
    ],
    default: [],
  }

  const cancel_reasons_sp: any = { default: [{ label: UNKNOW_REASON, value: "UNKNOW_REASON" }] }

  let cancel_reasons = shippingtype.toUpperCase() === "SP" ? cancel_reasons_sp : cancel_reasons_hd

  return cancel_reasons[current_status.toUpperCase()] || cancel_reasons.default
}

export default get_cancel_reasons
