// TODO: Use global variable to get current client/website
import Env from "classes/Env"

const dataFields: any = {
  [Env.theme.core.id]: {
    name: {
      label: "Nombre",
      input: "customerName",
      type: "text",
      order: 1,
    },
    direction: {
      label: "Dirección",
      input: "address",
      type: "text",
      order: 2,
    },
    commune: {
      label: "Comuna",
      input: "commune",
      type: "text",
      order: 3,
    },
    contactName: {
      label: "Recibe",
      input: "contactName",
      type: "text",
      order: 4,
    },
    reference: {
      label: "Observación",
      input: "reference",
      type: "text",
      order: 5,
    },
    contactPhone: {
      label: "Contacto",
      input: "contactPhone",
      type: "text",
      order: 6,
    },
  }
}

export default dataFields
